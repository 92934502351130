"use client";

import * as React from "react";
import { Edit, Folder, ImageIcon, MoreHorizontal, Sparkles, Trophy } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
export default function FloatingCTAToolbar() {
  const router = useRouter();
  const {
    pathname
  } = router;

  // Check if the current path is /edit or /generate
  if (pathname === "/edit" || pathname === "/generate") {
    return null; // Do not render the toolbar
  }
  return <div className="fixed top-0 left-0 right-0 z-50 w-auto sm:w-full md:bottom-4 md:top-auto md:left-1/2 md:-translate-x-1/2 md:w-auto md:max-w-fit" data-sentry-component="FloatingCTAToolbar" data-sentry-source-file="index.tsx">
            <div className="bg-gray-900/95 backdrop-blur-sm border-t border-gray-800 md:border md:rounded-full shadow-lg flex items-center justify-between p-2 md:px-4">
                <Link href="/edit" title="Edit Image" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Button variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-800 rounded-full transition-all duration-200 flex-1 md:flex-initial" aria-label="Edit" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        <Edit className="h-5 w-5 md:mr-2" data-sentry-element="Edit" data-sentry-source-file="index.tsx" />
                        <span className="hidden md:inline text-sm font-medium">
                            Edit
                        </span>
                    </Button>
                </Link>
                <Link href="/generate" title="Generate Image" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Button className="bg-gradient-to-r from-sky-500 to-blue-700 hover:from-sky-600 hover:to-blue-800 text-white font-medium px-4 py-2 md:px-6 md:py-3 !rounded-full shadow-md hover:shadow-lg transition-all duration-200 flex items-center justify-center flex-1 md:flex-initial" aria-label="Generate" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        <Sparkles className="h-5 w-5 mr-2" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                        <span className="text-base font-semibold">
                            Generate
                        </span>
                    </Button>
                </Link>

                <Popover data-sentry-element="Popover" data-sentry-source-file="index.tsx">
                    <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="index.tsx">
                        <Button variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-800 rounded-full transition-all duration-200 md:flex-initial" aria-label="More options" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                            <MoreHorizontal className="h-5 w-5 md:mr-2" data-sentry-element="MoreHorizontal" data-sentry-source-file="index.tsx" />
                            <span className="hidden md:inline text-sm font-medium">
                                More
                            </span>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-48 bg-gray-800 border-gray-700 text-white p-2" data-sentry-element="PopoverContent" data-sentry-source-file="index.tsx">
                        <div className="flex flex-col space-y-2">
                            <Link href="/gallery" title="Gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <Button variant="ghost" className="justify-start" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    <ImageIcon className="h-5 w-5 mr-2" data-sentry-element="ImageIcon" data-sentry-source-file="index.tsx" />
                                    Gallery
                                </Button>
                            </Link>
                            <Link href="/competitions" title="Competitions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <Button variant="ghost" className="justify-start" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    <Trophy className="h-5 w-5 mr-2" data-sentry-element="Trophy" data-sentry-source-file="index.tsx" />
                                    Competitions
                                </Button>
                            </Link>
                            <Link href="/my-gallery" title="Folder Management" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <Button variant="ghost" className="justify-start" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    <Folder className="h-5 w-5 mr-2" data-sentry-element="Folder" data-sentry-source-file="index.tsx" />
                                    Folders
                                </Button>
                            </Link>
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </div>;
}