globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"chargen-fe@80343e91f48683d4acbdae0f21f02656d906f71e"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/react";

const env = process.env.NODE_ENV;

const release = process.env.NEXT_PUBLIC_SENTRY_RELEASE;

Sentry.init({
    environment: env,
    dsn: "https://aec796a5cae306752ef8517cdb44987d@sentry.vorontsov.co.uk/1",
    release: release || "",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                "localhost",
                /api\.char-gen\.com/,
                "https://cognito-idp.eu-west-2.amazonaws.com",
            ],
            networkRequestHeaders: ["X-Custom-Header"],
            networkResponseHeaders: ["X-Custom-Header"],
        }),
    ],
    enabled: true,
    tracePropagationTargets: ["localhost", /api\.char-gen\.com/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
});
