"use client";

import * as React from "react";
import { ChevronDown, ChevronUp, Edit, Sparkles, Upload, Video } from "lucide-react";
import Link from "next/link";
import { Button } from "@/components/ui/button";
export default function SidebarGenerateOptions() {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return <div className="p-4 bg-transparent rounded-lg shadow-md" data-sentry-component="SidebarGenerateOptions" data-sentry-source-file="index.tsx">
            <Button onClick={() => setIsExpanded(!isExpanded)} className="w-full bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 text-white font-medium py-2 px-4 rounded-md shadow-md hover:shadow-lg transition-all duration-200 ease-in-out flex items-center justify-between" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                <span className="flex items-center">
                    <Sparkles className="w-5 h-5 mr-2" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                    Generate
                </span>
                {isExpanded ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
            </Button>

            {isExpanded && <div className="bg-slate-950 space-y-2 flex flex-col">
                    <Link title="Generate Image" href="/generate">
                        <Button variant="ghost" className="w-full flex items-center justify-start text-white hover:bg-gray-700">
                            <Sparkles className="mr-2 h-4 w-4" />
                            Generate Image
                        </Button>
                    </Link>

                    <Link title="Generate Video" href="/generate?contentType=video">
                        <Button variant="ghost" className="w-full flex items-center justify-start text-white hover:bg-gray-700">
                            <Video className="mr-2 h-4 w-4" />
                            Generate Video
                        </Button>
                    </Link>

                    <Link title="Upload Image" href="/generate?formType=upload">
                        <Button variant="ghost" className="w-full flex items-center justify-start text-white hover:bg-gray-700">
                            <Upload className="mr-2 h-4 w-4" />
                            Upload Image
                        </Button>
                    </Link>
                    <Link title="Edit Image" href="/edit">
                        <Button variant="ghost" className="w-full flex items-center justify-start text-white hover:bg-gray-700">
                            <Edit className="mr-2 h-4 w-4" />
                            Edit Image
                        </Button>
                    </Link>
                </div>}
        </div>;
}